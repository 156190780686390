import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { environment } from './environments/environment';


import { AppModule } from './app/app.module';

Sentry.init({
  dsn: environment.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://api.docugen.io'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  release: '12.0',

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  environment: environment.production ? 'prod' : 'dev'
});


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
