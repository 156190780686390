<footer style='background-color: #F5F6F8;'>
  <nav fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <a
      mat-button
      class="mat-small"
      href="https://docugen.io/terms-of-service/"
      target="_blank"
      >Terms of Service</a
    >
    <span> | </span>
    <a
      mat-button
      class="mat-small"
      href="https://docugen.io/privacy-policy/"
      target="_blank"
      >Privacy Policy</a
    >
  </nav>
</footer>
