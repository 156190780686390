import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

import { SentryErrorHandler, ErrorHandlerFactory } from './sentry.module';
import { FooterComponent } from './components/footer/footer.component';
import { AppSyncService } from './services/app-sync.service';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DocumentGenViaEmailComponent } from './pages/document-gen-via-email/document-gen-via-email.component';

// /* Add Amplify imports */
// import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
// import Amplify from 'aws-amplify';
// import awsconfig from '../aws-exports';

// /* Configure Amplify resources */
// Amplify.configure(awsconfig);
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';



@NgModule({
  declarations: [AppComponent, FooterComponent, DocumentGenViaEmailComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule
  ],
  providers: [
    AppSyncService,
    // {
    //   provide: ErrorHandler,
    //   useValue: Sentry.createErrorHandler({
    //     showDialog: false,
    //   }),
    // },
    // {
    //   provide: Sentry.TraceService,
    //   deps: [Router],
    // },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: () => () => { },
    //   deps: [Sentry.TraceService],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
