/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DynamoService } from 'src/app/services/dynamo.service';
import { DocuGenDocument } from 'src/app/shared/interfaces/docugen';

@Component({
  selector: 'app-document-gen-via-email',
  templateUrl: './document-gen-via-email.component.html',
  styleUrls: ['./document-gen-via-email.component.scss']
})
export class DocumentGenViaEmailComponent implements OnInit {
  document: DocuGenDocument;
  isAuthorized: boolean = false;
  session: string;

  constructor(
    private route: ActivatedRoute,
    private dynamo: DynamoService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.paramMap.get('id') && this.route.snapshot.queryParamMap.get('session')) {
      this.session = this.route.snapshot.queryParamMap.get('session');
      const accountId = parseInt(this.route.snapshot.paramMap.get('accountId'), 10);
      const documentId = this.route.snapshot.paramMap.get('id');

      this.getDocumentDetails(accountId, documentId, this.session);
    } else {
      this.router.navigate(['unauthorized']);
    }
  }

  getDocumentDetails(accountId: number, documentId: string, sessionToken: string): void {
    this.dynamo.getDocumentDetails(documentId, accountId, sessionToken)
      .pipe(
        tap(res => {
          this.document = res;
        })
      ).subscribe();
  }

  getExt(): string {
    if (this.document) {
      return this.document.type === 'WordDocument-OpenXml-Email' ? '.docx' :
        this.document.type === 'PDF-OpenXml-Email' ? '.pdf' : '.rtf';
    }
  }

}
