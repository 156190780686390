import { Injectable } from '@angular/core';
import AWSAppSyncClient from 'aws-appsync';
import aws_exports from '../../aws-exports';
import { AUTH_TYPE } from 'aws-appsync';
// import { Auth } from 'aws-amplify';

@Injectable()
export class AppSyncService {

  // hc;
  hydratedClient: AWSAppSyncClient<any>;

  constructor() {
    const client = new AWSAppSyncClient({
      url: aws_exports.aws_appsync_graphqlEndpoint,
      region: aws_exports.aws_project_region,
      auth: {
        type: AUTH_TYPE.API_KEY,
        apiKey: 'da2-th3isld4wnbh5lscxcqcgcgotm'
      },
      disableOffline: true
    });
    // this.hc = client.hydrated;
    this.hydratedClient = client;
  }
  hc() {
    return this.hydratedClient.hydrated();
  }
}
