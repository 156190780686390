import { Injectable, HostBinding, Renderer2, ElementRef, RendererFactory2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as mondaySdk from 'monday-sdk-js';
import { OverlayContainer } from '@angular/cdk/overlay';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MondayService {
  @HostBinding('class') componentCssClass;
  public userSessionToken: BehaviorSubject<any> = new BehaviorSubject(null);
  public accountData: BehaviorSubject<any> = new BehaviorSubject(null);
  monday = mondaySdk();

  private renderer: Renderer2;

  constructor(
    private http: HttpClient,
    private overlayContainer: OverlayContainer,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  get sessionToken(): Promise<any> {
    // if (this.userSessionToken.value !== null) {
    //   return this.userSessionToken.toPromise();
    // }
    return this.monday.get('sessionToken');
  }

  oAuth(clientId: string, mondayOauthUrl: string) {
    return this.monday.oauth({
      clientId,
      mondayOauthUrl
    });
  }

  query(query: string, variables = {}): Promise<any> {
    return this.monday.api(query, { variables });
  }

  notice(message: string, type: string, timeout: number): void {
    this.monday.execute('notice', { message, type, timeout });
  }

  confirm(message: string, confirmButton: string, cancelButton: string, excludeCancelButton: boolean) {
    return this.monday.execute('confirm',
      {
        message,
        confirmButton,
        cancelButton,
        excludeCancelButton
      });
  }

  execute(command?, options?) {
    // this.monday.execute('context', {
    //   viewMode: 'split'
    // });
  }

  getItem(key): Promise<any> {
    return this.monday.storage.instance.getItem(key);
  }

  setItem(key, value): Promise<any> {
    return this.monday.storage.instance.setItem(key, value);
  }

  contextCallBack(context, el) {
    // this.execute();
    // // Toggle light/dark theme
    // if (context.data.theme === 'dark') {
    //   this.onSetTheme('docugen-dark-theme', '#1c1f3b', '#e6e9ef', el);
    // } else {
    //   this.onSetTheme('docugen-light-theme', '#FFFFFF', '#000000', el);
    // }
  }

  // Theming
  onSetTheme(theme, background, foreground, el: ElementRef) {
    this.overlayContainer.getContainerElement().classList.add(theme);
    this.componentCssClass = theme;
    this.renderer.setStyle(el.nativeElement.ownerDocument.body, 'backgroundColor', background);
    this.renderer.setStyle(el.nativeElement.ownerDocument.body, 'color', foreground);
    // this.renderer.setStyle()

  }

}

