// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/* eslint-disable */
export const environment = {
  production: false,
  appUrl: 'https://uat.docugen.cloudconceptgroup.com',
  // appUrl: 'https://uat.docugen.io',
  // appUrl: 'http://localhost:4200',

  monday: {
    // clientId: '597ac3c7a112d862feb90402325864a1'  // DEV
    clientId: '2b8843128671763ce952ce924c813a74' // STAGING
  },


  docugen: {
    API_ENDPOINT: 'https://api.docugen.io/STAGING',
    // API_ENDPOINT: 'https://api.docugen.io/DEV'
  },


  sentry: {
    dsn: 'https://f43de10c3f174f02b2fa34f4d25dc9fb@o401708.ingest.sentry.io/5274939'
  }


};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
