import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentGenViaEmailComponent } from './pages/document-gen-via-email/document-gen-via-email.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'documents/:accountId/:id',
    component: DocumentGenViaEmailComponent
  },
  { path: '**', redirectTo: '/docugen', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
