import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { take, catchError, retry, switchMap, tap, map } from 'rxjs/operators';


import { DocuGenAnnouncement, DocuGenUserSettings, FSAuthenticatedAccountResponse } from '../shared/interfaces/docugen';

import { marshall, unmarshall } from '@aws-sdk/util-dynamodb';
import { MondayService } from './monday.service';

// import * as DynamoDB from 'aws-sdk/clients/dynamodb';

@Injectable({
  providedIn: 'root'
})
export class DynamoService {
  public settingsSubject$: BehaviorSubject<DocuGenUserSettings | any> = new BehaviorSubject(null);
  settings$: Observable<DocuGenUserSettings | any> = this.settingsSubject$.asObservable();

  public settingsInitData$: BehaviorSubject<any> = new BehaviorSubject(null);
  public settingsFetched: boolean = false;

  public userSubject$: BehaviorSubject<any> = new BehaviorSubject(null);
  public userDataFetched = false;



  public plansSubject$ = new BehaviorSubject<any>(null);
  public plansFetched = false;

  public currentPlan$ = new BehaviorSubject<any>(null);
  public currentPlanFetched = false;

  public accountURL$ = new BehaviorSubject<string>(null);
  public accountURLFetched = false;


  public featuresSubject$ = new BehaviorSubject<any>(null);
  featuresFetched = false;


  private readonly api: string = `${environment.docugen.API_ENDPOINT}/dynamo`;

  constructor(private http: HttpClient, private monday: MondayService) { }
  // Create user record if no details for the current user exist, but account exists
  createRecord(
    userName: string,
    userId: number,
    accountId: number,
    accountName: string,
    googleUId: string,
    email: string,
    country: string,
    action: string
  ): Observable<any> {

    const queryParams = new HttpParams()
      .append('action', action);

    const payload = {
      userId,
      accountId,
      accountName,
      googleUId,
      email,
      country,
      name: userName
    };
    const endpoint = `${environment.docugen.API_ENDPOINT}/account`;

    return this.http.post(endpoint,
      payload, { params: queryParams }).pipe(
        take(1),
        switchMap(res => {
          const response: any = res;
          if (response?.statusCode === 200) {
            return of(null);
          }
        }),
        catchError(error => throwError(error))
      );
  }

  initAccountAndUser(
    name: string,
    userId: number,
    accountId: number,
    accountName: string,
    email: string,
    country: string,
    action: string
  ): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/account`;

    const payload = {
      name,
      userId,
      email,
      country,
      accountId,
      accountName
    };

    return this.http
      .post(endpoint, payload, {
        params: new HttpParams()
          .append('action', action)
      })
      .pipe(
        take(1),
        switchMap(res => {
          const response: any = res;
          if (response?.statusCode === 200) {
            return of('Success');
          }
          return of('Failed');
        }),
        catchError(error => throwError(error))
      );
  }

  initUser(
    name: string,
    userId: number,
    accountId: number,
    accountName: string,
    email: string,
    country: string,
    action: string
  ): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/account`;

    const payload = {
      name,
      userId,
      email,
      country,
      accountId,
      accountName
    };

    return this.http
      .post(endpoint, payload, {
        params: new HttpParams()
          .append('action', action)
      })
      .pipe(
        take(1),
        switchMap(res => {
          const response: any = res;
          if (response?.statusCode === 200) {
            return of('Success');
          }
          return of('Failed');
        }),
        catchError(error => throwError(error))
      );
  }

  get getUserData(): Observable<any> {
    return this.userSubject$.asObservable().pipe(take(1));
  }


  saveNewSettings(payload: DocuGenUserSettings): Observable<any> {
    const params = new HttpParams()
      .append('action', 'save-new-settings');


    return this.http.post(this.api, payload, { params })
      .pipe(
        take(1),
        catchError(error => throwError(new Error(error)))
      );
  }

  saveSettings(payload: DocuGenUserSettings, userId: number, accountId: number, boardId: number, viewId: number): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/${boardId}/${viewId}/settings`;

    return this.http
      .post(endpoint, payload, {
        params: new HttpParams()
          .append('action', 'save-settings')
      })
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );
  }

  updateListOfViews(boardId: number, viewId: number, accountId: number, userId: number, viewName?: string): void {
    // Query the views on the board
    const query = `
      query get_views($boardId: Int!) {
        boards(ids: [$boardId]) {
          views {
            id
            name
            type
          }
        }
      }
    `;

    // console.log('About to get views');
    this.monday.query(query, { boardId: +boardId })
      .then((res) => {
        if (res?.data && res?.data?.boards && res?.data?.boards.length > 0) {
          const { boards } = res.data;
          if (boards[0]?.views && boards[0]?.views.length > 0) {
            const { views } = boards[0];
            const featureBoardViews = views.filter(view => view.type === 'FeatureBoardView');
            featureBoardViews.push({
              id: +viewId,
              name: viewName,
              type: 'FeatureBoardView'
            });

            const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/${boardId}/${viewId}/settings`;
            // console.log('About to update views');
            this.http
              .post(endpoint, {
                views: featureBoardViews
              }, {
                params: new HttpParams()
                  .append('action', 'update-views')
              })
              .pipe(
                take(1),
                catchError(error => throwError(error))
              ).subscribe();

          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  }

  getActivePlans(): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/dynamo`;
    const params = new HttpParams()
      .append('action', 'get-active-plans');



    return this.http.post(endpoint, {}, {
      params
    })
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );
  }

  get getPlans(): Observable<any> {
    return this.plansSubject$.asObservable().pipe(take(1));
  }

  getActivePlan(planId: string): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/account/plans/${planId}`;


    if (this.currentPlanFetched) {
      return this.currentPlan$.asObservable().pipe(take(1));
    }
    return this.http.get(endpoint)
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );
  }

  getFSAccount(accountId: string, mondayAccountId: number, mondayUserId: number): Observable<FSAuthenticatedAccountResponse> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/${mondayAccountId}/${mondayUserId}/fastspring/${accountId}`;

    return this.http.get<FSAuthenticatedAccountResponse>(endpoint)
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );
  }

  get getAuthenticatedAccountURL(): Observable<string> {
    return this.accountURL$.asObservable().pipe(take(1));
  }


  upgradeSubscription(subscriptionId: string, accountId: number, userId: number): Observable<any> {
    // const endpoint = `${environment.docugen.API_ENDPOINT}/account`;
    const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/account`;
    const params = new HttpParams()
      .append('action', 'update-subscription');


    const payload = { subscriptionId };

    return this.http.post(endpoint, payload, { params })
      .pipe(
        take(1),
        catchError(error => throwError(new Error(error)))
      );
  }

  createFastSpringAccount(first: string, last: string, email: string): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/account/fastspring/accounts`;


    const payload = {
      first,
      last,
      email
    };

    return this.http.post(endpoint, payload)
      .pipe(
        take(1),
        catchError(error => throwError(new Error(error)))
      );
  }

  createFastSpringSession(product: string, account: string, mondayAccountId: number, mondayAccountName: string) {
    const endpoint = `${environment.docugen.API_ENDPOINT}/account/fastspring/session`;


    const payload = {
      product,
      account,
      mondayAccountId,
      mondayAccountName
    };

    return this.http.post(endpoint, payload)
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );
  }


  // Responsible for loading user's account data and settings.
  loadUserData(userId: number, accountId: number): Observable<any> {
    if (this.userDataFetched) {
      return this.getUserData;
    }

    const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/account`;

    return this.http.get(endpoint,
    )
      .pipe(
        take(1),
        switchMap(res => {
          const response: any = res;
          if (!(Object.keys(res).length === 0 && res.constructor === Object)) {

            if (environment.production) {
              const users = unmarshall(response.Responses.users[0] ?? {});
              const accounts = unmarshall(response.Responses.accounts[0] ?? {});

              this.userSubject$.next({
                users,
                accounts
              });

              this.userDataFetched = true;
            } else {
              const users = unmarshall(response.Responses.users_dev[0] ?? {});
              const accounts = unmarshall(response.Responses.accounts_dev[0] ?? {});


              this.userSubject$.next({
                users,
                accounts
              });

              this.userDataFetched = true;
            }
          }
          if (response.user && response.account) {
            this.userDataFetched = true;
          }
          return this.userSubject$.asObservable().pipe(
            take(1)
          );
        }),
        catchError(error => throwError(error))
      );
  }


  getSettings(userId: number, boardId: number, viewId: number, accountId: number): Observable<DocuGenUserSettings> {
    if (this.settingsFetched) {
      return this.settings$;

    } else {
      // const endpoint = `${environment.docugen.API_ENDPOINT}/settings/${accountId}/${boardId}/${viewId}`;
      const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/${boardId}/${viewId}/settings`;
      return this.http.get<DocuGenUserSettings>(
        endpoint
      ).pipe(
        take(1),
        tap(res => {
          const response: any = res;

          this.settingsFetched = true;

          if (response?.Item) {
            const unmarshal = unmarshall(response.Item);
            this.settingsSubject$.next(unmarshal);
          } else {
            // Settings don't exist
            this.loadDefaultSettings(userId, boardId, viewId, accountId);

            this.saveSettings(
              this.settingsSubject$.value,
              userId,
              accountId,
              boardId,
              viewId
            ).pipe(
              take(1)
            ).subscribe();

            // this.saveNewSettings(this.settingsSubject$.value)
            //   .pipe(
            //     take(1)
            //   ).subscribe();
          }
        }),
        switchMap(() => (
          // this.settings$
          this.settings$.pipe(
            // take(1)
          )
        )),
        catchError(error => throwError(error))
      );
    }
  }

  getFeatures(userId: number, boardId: number, viewId: number, accountId: number): Observable<any> {
    if (this.featuresFetched) {
      return this.featuresSubject$
        .asObservable()
        .pipe(
          take(1)
        );
    } else {
      const endpoint = `${environment.docugen.API_ENDPOINT}/${accountId}/${userId}/${boardId}/${viewId}/features/9-0-0`;

      return this.http
        .get<any>(endpoint)
        .pipe(
          take(1),
          tap(res => {
            const response: any = res;

            this.featuresFetched = true;
            if (response?.Item) {
              const item = unmarshall(response.Item);
              this.featuresSubject$.next(item);
            } else {
              this.featuresSubject$.next({
                deliveryOptions: {
                  email: true,
                  attachToConversationFeed: false,
                  attachToFileColumn: false
                }
              });
            }
          }),
          switchMap(() => this.featuresSubject$.asObservable().pipe(take(1)))
        );
    }
  }

  getDocumentDetails(documentId: string, accountId: number, sessionToken: string): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/documents/${accountId}/${documentId}`;

    return this.http.get<any>(endpoint, {
      headers: new HttpHeaders()
        .append('Authorization', sessionToken)
    })
      .pipe(
        map(res => {
          if (Object.keys(res).length > 0 && 'Item' in res) {
            return unmarshall(res.Item);
          } else {
            return null;
          }
        })
      );
  }

  dismissAnnouncement(id: string, userId: number): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/announcements/dismiss`;

    return this.http.put(endpoint, {
      id,
      userId
    })
      .pipe(
        take(1),
        catchError(error => throwError(error))
      );

  }

  getActiveAnnouncement(userId: number): Observable<any> {
    const endpoint = `${environment.docugen.API_ENDPOINT}/announcements`;

    return this.http.get<any>(endpoint, {
      params: new HttpParams()
        .set('userId', userId.toString())
    })
      .pipe(
        take(1),
        map(res => {
          if (res?.Items && res?.Items.length > 0) {
            return unmarshall(res.Items[0]);
          } else {
            return null;
          }
        }),
        catchError(error => throwError(error))
      );
  }

  private loadDefaultSettings(userId: number, boardId: number, viewId: number, accountId: number, viewName?: string): void {
    const defaultSettings: any = {
      flags: {
        includeHeader: true,
        shareWithBoardSubscribers: false,
        isFirstTime: true,
      },
      accountId,
      userId,
      boardId,
      viewId,
      columns: {
        selected: []
      },
      exportOptions: {
        /* eslint-disable @typescript-eslint/naming-convention */
        MicrosoftWord: true,
        PDF: false,
        RichTextFormat: false,
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      deliveryOptions: {
        attachToFileColumn: {
          isEnabled: false
        },
        attachToConversationFeed: {
          isEnabled: false
        },
        email: true
      },
      createdAt: new Date().toISOString()
    };
    this.settingsSubject$.next(defaultSettings);
  }




}
