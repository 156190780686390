<main style='background: #f5f6f8; min-height: 100vh'>
    <section fxLayout="row" fxLayoutAlign="flex-start center" class="p-3">
        <img
          src="../../../assets/DocuGen-logo.png"
          alt="DocuGen Logo"
          height="60"
          style="margin-bottom: 1rem; height: 60px;"
        />
        <div>
          <h1 style="margin-bottom: 0.2rem; font-weight: 500" class="mat-headline">
            DocuGen
          </h1>
        </div>
    </section>

    <section fxLayout="column" fxLayoutAlign="flex-start center" *ngIf="document">
        <mat-card style="width: 500px;" class="mat-elevation-z1">
            <p style="font-weight: 400; font-family:'Roboto', sans-serif;">
                Document '{{ document.name }}{{ getExt() }}' was sent by email on {{ document.generatedAt | date:'yyyy-MM-d hh:mm a' }} to the following recipients:
            </p>
            <ul style="font-weight: 400; font-family:'Roboto', sans-serif;">
              <ng-container *ngIf="document.mailedTo.length > 0">
                  <li *ngFor="let email of document.mailedTo">
                  -  {{ email }}
                  </li>
                </ng-container>
                <ng-container *ngIf="document.mailedTo.length === 0">
                  {{ document.generatedByUser.name }}
                </ng-container>
                
            </ul>
        </mat-card>
    </section>
</main>